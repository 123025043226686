import { Link } from '@remix-run/react';
import { ArrowRightIcon } from 'lucide-react';
import { Button } from '~/components/button';
import { Container } from '~/components/container';
import { Logo } from '~/components/logo';
import { HStack } from '~/components/stack';
import { ThemeToggle } from '~/components/theme-toggle';
import { ROUTES } from '~/utils/routes';

export function LandingNavbar() {
  return (
    <div className="h-landing-navbar sticky top-0 bg-card z-50">
      <Container className="h-full flex items-center justify-between">
        <Link to={ROUTES.LANDING.HERO}>
          <Logo />
        </Link>
        <HStack className="items-center gap-6 hidden lg:flex">
          <Link to={ROUTES.LANDING.FEATURES}>Features</Link>
          <Link to={ROUTES.LANDING.PRICING}>Pricing</Link>
          <Link to={ROUTES.LANDING.REVIEWS}>Reviews</Link>
          <ThemeToggle />
          <Link to={ROUTES.AUTH.SIGN_IN}>
            <Button variant="outline">Sign in</Button>
          </Link>
          <Link to={ROUTES.DECKS.BROWSE}>
            <Button variant="theme" iconEnd={<ArrowRightIcon size={16} />}>
              Try for free
            </Button>
          </Link>
        </HStack>
      </Container>
    </div>
  );
}
