import { Link } from '@remix-run/react';
import { Container } from '~/components/container';
import { Logo } from '~/components/logo';
import { Spacer, Stack, VStack } from '~/components/stack';
import { ROUTES } from '~/utils/routes';

export function LandingFooter() {
  return (
    <Container className="py-16">
      <VStack className="items-center gap-16">
        <p className="max-w-[700px] text-center text-muted-foreground">
          MEDizzy has no affiliation with, nor is it endorsed by, any of the
          mentioned organizations. All organization and test names are
          trademarks of their respective owners.
        </p>
        <Stack className="w-full gap-8 lg:gap-16 flex-col md:flex-row">
          <Link to={ROUTES.LANDING.HERO}>
            <Logo />
          </Link>
          <VStack className="gap-4">
            <h3 className="text-lg font-bold">Legal</h3>
            <ul className="flex flex-col gap-2">
              <li>
                <Link to={ROUTES.LEGAL.TERMS_OF_SERVICE}>Terms of Service</Link>
              </li>
              <li>
                <Link to={ROUTES.LEGAL.PRIVACY_POLICY}>Privacy Policy</Link>
              </li>
            </ul>
          </VStack>
          <Spacer />
          <VStack className="gap-4">
            <h3 className="text-lg font-bold">Contact</h3>
            <ul className="flex flex-col gap-2">
              <li>MEDizzy LTD</li>
              <li>85 Uxbridge Road</li>
              <li>W5 5BW London, UK</li>
              <li>
                <a href="mailto:contact@medizzy.com">contact@medizzy.com</a>
              </li>
            </ul>
            <img
              loading="lazy"
              src="/img/landing/footer.png"
              alt="UK"
              className="max-w-[228px]"
            />
          </VStack>
        </Stack>
      </VStack>
    </Container>
  );
}
